import {
  BIBLIOFIL,
  BOOKIT,
  CICERO,
  CICERO_DEICHMAN,
  DEICHMAN,
  FBS,
  MIKROMARC,
  QURIA,
} from "../constants/constants";

export function validateRegExp(value: string, pattern: RegExp) {
  var regExp = new RegExp(pattern);
  const result = regExp.test(value);
  return result;
}

export function isNumber(n: string) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

export function isJSON(value: string) {
  if (
    /^[\],:{}\s]*$/.test(
      value
        .replace(/\\["\\bfnrtu]/g, "@")
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+]?\d+)?/g,
          "]"
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export function validateHexValue(value: string) {
  var regExp = new RegExp(/([0-9A-Fa-f]){2}$/);
  return !regExp.test(value);
}

export function libraryServiceIsBibliofil(libService?: string) {
  if (libService) {
    return libService === BIBLIOFIL;
  } else return false;
}

export function libraryServiceIsBookit(libService?: string) {
  if (libService) {
    return libService === BOOKIT;
  } else return false;
}

export function libraryServiceIsCicero(libService?: string) {
  if (libService) {
    return libService === CICERO;
  } else return false;
}

export function libraryServiceIsCiceroDeichman(libService?: string) {
  if (libService) {
    return libService === CICERO_DEICHMAN;
  } else return false;
}

export function libraryServiceIsDeichman(libService?: string) {
  if (libService) {
    return libService === DEICHMAN;
  } else return false;
}

export function libraryServiceIsFBS(libService?: string) {
  if (libService) {
    return libService === FBS;
  } else return false;
}

export function libraryServiceIsMikromarc(libService?: string) {
  if (libService) {
    return libService === MIKROMARC;
  } else return false;
}

export function libraryServiceIsQuria(libService?: string) {
  if (libService) {
    return libService === QURIA;
  } else return false;
}
