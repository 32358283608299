import { getUnixTime } from "date-fns";
import {
  ActionTypeMap,
  ActionTypeMapPlural,
  BlockItem,
  DayNameMap,
  EndConditionMap,
  ModeMap,
  PortTypeMap,
  Ruleset,
  SignalTypeMap,
  ValidationRule,
} from "../types/access";
import { getLocalizedDayName } from "../utils/locale";
import { capitalizeFirstLetter } from "../utils/utils";

export const actionTypes: ActionTypeMap = {
  DOOR: "door",
  LIGHT: "light",
  ALARM: "alarm",
  AUDIO: "audio",
  DOORPANEL: "doorpanel",
  DOORPANELCAMEL: "doorPanel",
};

export const actionTypesPlural: ActionTypeMapPlural = {
  door: "doors",
  light: "lights",
  alarm: "alarms",
  audio: "audios",
  doorpanel: "doorpanels",
  doorPanel: "doorPanels",
};

export const modeMap: ModeMap = {
  CLOSED: "closed",
  OPEN: "open",
  MANAGED: "managed",
};

export const portTypeMap: PortTypeMap = {
  ACTIVATE_PORT: "activatePort",
  DEACTIVATE_PORT: "deactivatePort",
  MOTOR_PORT: "motorPort",
  RADAR_PORT: "radarPort",
};

export const signalTypeMap: SignalTypeMap = {
  OUTPUT: "output",
  INPUT: "input",
};

export const secretDots: string = "••••••••••";

export const defaultRuleset: Ruleset = {
  accessValidationRules: [],
  branchIds: [],
  customerCode: "",
  id: "",
  loginWithoutPassword: false,
  name: "",
  order: 0,
};

export const defaultValidationRule: ValidationRule = {
  activateAgeRestriction: false,
  activateIdPrefixGreaterThan: false,
  activateIdPrefixLessThan: false,
  ageRestriction: 0,
  idPrefixGreaterThan: 0,
  idPrefixLessThan: 0,
  activateLmsValidation: false,
  name: "",
};

export const defaultBlockItem: BlockItem = {
  blockedUntil: null,
  blockedFrom: getUnixTime(new Date()),
  note: "",
  branchCodes: [],
  id: "",
};

export const errorUserAlreadyBlocked: string = "user is already blocked";

export const endConditions: EndConditionMap = {
  NEVER: "never",
  ON: "on",
  AFTER: "after",
};

export const boundaryFirst = "first";
export const boundaryLast = "last";
