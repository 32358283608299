import { RoleLevel } from "../types/authentication";

export const EMPLOYEE = 200;
export const INSPECTOR = 300;
export const EDITOR = 400;
export const ADMIN = 600;
export const PARTNERADMIN = 800;
export const SYSTEMEMPLOYEE = 900;
export const SYSTEMADMIN = 1000;
export const NONE = 2000;

export const roleLevelMap: { [key: string]: RoleLevel } = {
  admin: ADMIN,
  editor: EDITOR,
  employee: EMPLOYEE,
  inspector: INSPECTOR,
  partneradmin: PARTNERADMIN,
  systemadmin: SYSTEMADMIN,
};

export const EMPLOYEE_LITERAL = "employee";
export const INSPECTOR_LITERAL = "inspector";
export const EDITOR_LITERAL = "editor";
export const ADMIN_LITERAL = "admin";
export const PARTNER_ADMIN_LITERAL = "partnerAdmin";
export const SYSTEM_EMPLOYEE_LITERAL = "systemEmployee";
export const SYSTEM_ADMIN_LITERAL = "systemAdmin";
export const ASSIST_LIBRARIAN_LITERAL = "assistLibrarian";
export const ASSIST_LOGISTICS_LITERAL = "assistLogistics";
export const ASSIST_SECURITY_LITERAL = "assistSecurity";

export const assistRoleNames = [
  ASSIST_LIBRARIAN_LITERAL,
  ASSIST_LOGISTICS_LITERAL,
  ASSIST_SECURITY_LITERAL,
];
